body {
  color: #fff;
  background: #8e2de2;
  background: -webkit-linear-gradient(to right, #4a00e0, #8e2de2);
  background: linear-gradient(to right, #4a00e0, #8e2de2);
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

.module {
  overflow-x: hidden;
  max-width: 100vw !important;
}

.module.module-header {
  padding: 80px 0;
}

.module.module-header h1 {
  width: fit-content;
  margin: 0 auto;
  font-size: 3rem;
}

.module.module-header span {
  background-color: #0003;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
  padding: 10px;
  display: block;
}

@media (width >= 768px) {
  .module.module-header h1 {
    font-size: 6rem;
  }
}

.module.module-hero {
  text-wrap: wrap;
  width: 80%;
  min-width: 300px;
  margin: 0 auto;
  padding: 60px 0 40px;
}

.module.module-hero h2 {
  text-align: center;
  border-bottom: 2px solid #fff3;
  padding: 20px 0;
  font-size: 2rem;
}

@media (width >= 768px) {
  .module.module-hero {
    width: 50%;
  }
}

.module.module-features .feature-wrapper {
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.module.module-features .feature-wrapper .feature-item {
  border-radius: 10px;
  margin: 10px 20px;
}

.module.module-features .feature-wrapper .feature-item:hover {
  background: #ffffff1a;
}

.module.module-features .feature-wrapper .feature-item__title {
  text-align: center;
  padding: 10px 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.module.module-features .feature-wrapper .feature-item__text {
  text-align: center;
  padding: 10px;
  font-size: 1.1rem;
}

@media (width >= 768px) {
  .module.module-features .feature-wrapper .feature-item {
    width: calc(33.3333% - 40px);
    padding: 0;
  }
}
/*# sourceMappingURL=index.0c601564.css.map */
