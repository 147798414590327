.module.module-header {
    padding: 80px 0;
    h1 {
        font-size: 3rem;
        width: fit-content;
        margin: 0 auto;
    }

    span {
        padding: 10px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, .2);
        margin: auto;
        display: block;
        width: fit-content;
    }
}

@media (min-width:768px) {
    .module.module-header {
        h1 {
            font-size: 6rem;
        }
    }
}