.module.module-features {
    
    .feature-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 0 auto;
    
        
        .feature-item {

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
            
            border-radius: 10px;
            margin: 10px 20px;

            &__title {
                padding: 10px 0;
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;
            }
    
            &__text {
                padding: 10px;
                text-align: center;
                font-size: 1.1rem;
            }
        }
    }

}

@media (min-width:768px) {
    .module.module-features {
        .feature-wrapper {
            .feature-item {
                padding: 0;
                width: calc(100% / 3 - 40px);
            }
        }
    }
}