body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;

    background: #8E2DE2;
    background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
    background: linear-gradient(to right, #4A00E0, #8E2DE2);

}

.module {
    max-width: 100vw !important;
    overflow-x: hidden;
}