.module.module-hero {
    min-width: 300px;
    width: 80%;
    text-wrap: wrap;
    padding: 60px 0 40px 0;
    margin: 0 auto;
    h2 {
        font-size: 2rem;
        text-align: center;
        padding: 20px 0;
        border-bottom: solid 2px rgba(255, 255, 255, .2);
    }   
}

@media (min-width:768px) {
    .module.module-hero {
        width: 50%;
    }
}